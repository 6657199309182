export default [
  {
    header: 'COMERCIAL',
    resource: 'rt-comercial',
  },
  {
    title: 'Tablero de Indicadores',
    route: 'rt-comercial-tablero-gerencia',
    icon: 'fa-solid fa-chart-line',
    typeIcon: 'awesome',
    resource: 'rt-comercial-tablero-gerencia',
  },
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ],
  },
  {
    title: 'Punto de Venta',
    icon: 'fa-hand-holding-dollar',
    typeIcon: 'awesome',
    route: 'rt-comercial-puntoventa',
    resource: 'rt-comercial-puntoventa',
  },
  // {
  //   title: 'Nueva de Venta',
  //   route: 'rt-comercial-nuevaventa',
  //   icon: 'DollarSignIcon',
  //   resource: 'rt-comercial-nuevaventa',
  // },
  {
    title: 'Clientes',
    route: 'rt-comercial-clientes',
    icon: 'fa-solid fa-user-group',
    typeIcon: 'awesome',
    resource: 'rt-comercial-clientes',
  },
  {
    title: 'Productos',
    route: 'rt-comercial-productos',
    icon: 'fa-solid fa-gas-pump',
    typeIcon: 'awesome',
    resource: 'rt-comercial-productos',
  },
  {
    title: 'Guias',
    route: 'rt-comercial-guiaremision',
    icon: 'fa-solid fa-file-circle-check',
    typeIcon: 'awesome',
    resource: 'rt-comercial-guiaremision',
  },
  {
    title: 'Compras',
    route: 'rt-comercial-compra',
    icon: 'fa-solid fa-cart-plus',
    typeIcon: 'awesome',
    resource: 'rt-comercial-compra',
  },
  {
    header: 'PRODUCCIÓN',
    resource: 'rt-produccion',
  },
  {
    title: 'Cisterna Primaria',
    icon: 'fa-solid fa-truck-moving',
    typeIcon: 'awesome',
    route: 'rt-produccion-cisterna-primaria',
    resource: 'rt-produccion-cisterna-primaria',
  },
  {
    title: 'Trasegado',
    icon: 'fa-solid fa-arrow-right-arrow-left',
    typeIcon: 'awesome',
    route: 'rt-produccion-trasegado',
    resource: 'rt-produccion-trasegado',
  },
  {
    header: 'RECURSOS HUMANOS',
    resource: 'rt-rrhh',
  },
  {
    title: 'Trabajadores',
    route: 'rt-rrhh-trabajadores',
    icon: 'fa-solid fa-id-card-clip',
    typeIcon: 'awesome',
    resource: 'rt-rrhh-trabajadores',
  },
  {
    header: 'CONTROL VIGILANCIA',
    resource: 'rt-viligancia',
  },
  {
    title: 'Ingreso / Salida',
    route: 'rt-viligancia-is',
    icon: 'fa-solid fa-person-military-to-person',
    typeIcon: 'awesome',
    resource: 'rt-viligancia-is',
  },
  {
    header: 'INVENTARIO',
    resource: 'rt-inventario',
  },
  {
    title: 'Flota',
    icon: 'fa-solid fa-truck-front',
    typeIcon: 'awesome',
    route: 'rt-inventario-flota',
    resource: 'rt-inventario-flota',
  },
  {
    header: 'Catálogos',
    resource: 'rt-catalogo',
  },
  {
    title: 'Presentacion',
    route: 'rt-catalogo-presentacion',
    icon: 'fa-solid fa-list-ol',
    typeIcon: 'awesome',
    resource: 'rt-catalogo-presentacion',
  },
  {
    title: 'Marca',
    route: 'rt-catalogo-marca',
    icon: 'fa-solid fa-tags',
    typeIcon: 'awesome',
    resource: 'rt-catalogo-marca',
  },
  {
    title: 'Tipo de Flota',
    route: 'rt-catalogo-tipoflota',
    icon: 'fa-solid fa-truck-arrow-right',
    typeIcon: 'awesome',
    resource: 'rt-catalogo-tipoflota',
  },
  {
    title: 'Empresa',
    route: 'rt-catalogo-empresa',
    icon: 'fa-solid fa-briefcase',
    typeIcon: 'awesome',
    resource: 'rt-catalogo-empresa',
  },
  {
    header: 'Seguridad',
    resource: 'rt-seguridad',
  },
  {
    title: 'Usuarios',
    route: 'rt-seguridad-usuarios',
    icon: 'fa-solid fa-user-shield',
    typeIcon: 'awesome',
    resource: 'rt-seguridad-usuarios',
  },
  {
    title: 'Sistemas',
    route: 'rt-seguridad-sistemas',
    icon: 'fa-brands fa-windows',
    typeIcon: 'awesome',
    resource: 'rt-seguridad-sistemas',
  },
  {
    title: 'Permisos',
    route: 'rt-seguridad-permisos',
    icon: 'fa-solid fa-list-check',
    typeIcon: 'awesome',
    resource: 'rt-seguridad-permisos',
  },
]
